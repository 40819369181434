import React from 'react';
import { Accordion } from '../ui/accordion';

interface FAQ {
  question: string;
  answer: string;
}

interface FAQSectionProps {
  faqs: FAQ[];
}

const FAQSection: React.FC<FAQSectionProps> = ({ faqs }) => {
  const accordionItems = faqs.map(faq => ({
    title: faq.question,
    content: <p>{faq.answer}</p>
  }));

  return (
    <section className="py-12 bg-gray-50 dark:bg-gray-800">
      <div className="max-w-4xl mx-auto px-4">
        <h2 className="text-3xl font-bold mb-8 text-center">Frequently Asked Questions</h2>
        <Accordion items={accordionItems} />
      </div>
    </section>
  );
};

export default FAQSection;