import React from 'react';
import CloudinaryImage from '@/components/CloudinaryImage';
import { Destination } from '@/types';
import { FaShieldAlt, FaWalking, FaHotel } from 'react-icons/fa';

interface HeroSectionProps {
  destinationData: Destination;
}

const HeroSection: React.FC<HeroSectionProps> = ({ destinationData }) => {
  return (
    <section className="relative h-[60vh] flex items-center justify-center">
      <CloudinaryImage
        src={`/images/${destinationData.value.toLowerCase()}.jpg`}
        alt={destinationData.name}
        fill
        style={{ objectFit: 'cover' }}
        priority
        format="auto"
        quality={80}
      />
      <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col items-center justify-center text-white">
        <h1 className="text-4xl md:text-6xl font-bold mb-4">{destinationData.name}</h1>
        <p className="text-xl md:text-2xl italic max-w-3xl mx-auto px-4 mb-8">
          {destinationData.description}
        </p>
      </div>
      <div className="absolute bottom-0 left-0 right-0 bg-white dark:bg-gray-800 bg-opacity-90 p-4">
        <div className="flex justify-around text-sm">
          <div className="flex items-center">
            <FaShieldAlt className="text-green-500 mr-2" />
            <span>Safety Index: <span className="font-bold">{destinationData.safety_index}/100</span></span>
          </div>
          <div className="flex items-center">
            <FaWalking className="text-blue-500 mr-2" />
            <span>Walkability: <span className="font-bold">{destinationData.walkability_score}/100</span></span>
          </div>
          <div className="flex items-center">
            <FaHotel className="text-yellow-500 mr-2" />
            <span>Avg. Hotel: <span className="font-bold">{destinationData.currencySymbol}{destinationData.avg_hotel_price}/night</span></span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;