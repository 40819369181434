// lib/openGraph.ts

import { Destination, Place } from '../types';
import { generateOpenGraphImageUrl } from './utils/cloudinaryLoader';

interface OpenGraphMetadata {
  title: string;
  description: string;
  url: string;
  siteName: string;
  images: Array<{
    url: string;
    width: number;
    height: number;
    alt: string;
  }>;
  locale: string;
  type: string;
}

export function generateOpenGraphMetadata(
  type: 'destination' | 'tool' | 'place' | 'category',
  data: Destination | { name: string; description: string } | (Place & { destination: string }) | { name: string; destination: string; placesCount: number },
  baseUrl: string
): OpenGraphMetadata {
  let title: string;
  let description: string;
  let url: string;
  let imageUrl: string;
  let imageAlt: string;

  switch (type) {
    case 'destination':
      const destinationData = data as Destination;
      title = `${destinationData.name} Travel Guide: Top Attractions & Tips`;
      description = `Discover the best of ${destinationData.name}. Explore top attractions, local cuisine, and insider tips for your perfect trip to ${destinationData.name}.`;
      url = `${baseUrl}/${destinationData.name.toLowerCase().replace(/ /g, '-')}`;
      imageUrl = generateOpenGraphImageUrl(`images/${destinationData.name.toLowerCase().replace(/ /g, '-')}`);
      imageAlt = `${destinationData.name} Travel Guide`;
      break;
    case 'tool':
      const toolData = data as { name: string; description: string };
      const formattedToolName = toolData.name.replace(/-/g, ' ').replace(/\b\w/g, l => l.toUpperCase());
      title = `${formattedToolName} | Destinations AI`;
      description = toolData.description;
      url = `${baseUrl}/tools/${toolData.name}`;
      imageUrl = generateOpenGraphImageUrl(`images/tools/${toolData.name}.jpg`);
      imageAlt = `${formattedToolName} - Destinations AI Tool`;
      break;
    case 'place':
      const placeData = data as Place & { destination: string };
      title = `${placeData.name} in ${placeData.destination} | Destinations AI`;
      description = placeData.unique_description || placeData.description || `Discover ${placeData.name} in ${placeData.destination}`;
      url = `${baseUrl}/${placeData.destination.toLowerCase().replace(/ /g, '-')}/${placeData.category?.toLowerCase().replace(/ /g, '-')}/${placeData.slug}`;
      imageUrl = generateOpenGraphImageUrl(`images/places/${placeData.destination.toLowerCase().replace(/ /g, '-')}/${placeData.slug}`);
      imageAlt = `${placeData.name} in ${placeData.destination}`;
      break;
    case 'category':
      const categoryData = data as { name: string; destination: string; placesCount: number };
      title = `${categoryData.placesCount} Best ${categoryData.name} in ${categoryData.destination} | Destinations AI`;
      description = `Discover the top ${categoryData.name.toLowerCase()} in ${categoryData.destination}. Explore detailed information, ratings, and reviews for the best places to visit.`;
      url = `${baseUrl}/${categoryData.destination.toLowerCase().replace(/ /g, '-')}/${categoryData.name.toLowerCase().replace(/\s+/g, '-')}`;
      imageUrl = generateOpenGraphImageUrl(`images/${categoryData.destination.toLowerCase().replace(/ /g, '-')}`);
      imageAlt = `Best ${categoryData.name} in ${categoryData.destination}`;
      break;
    default:
      throw new Error('Invalid type for OpenGraph metadata generation');
  }

  return {
    title,
    description,
    url,
    siteName: 'Destinations AI',
    images: [
      {
        url: imageUrl,
        width: 1200,
        height: 630,
        alt: imageAlt,
      },
    ],
    locale: 'en_US',
    type: 'website',
  };
}
