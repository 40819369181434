import React from 'react';
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';
import Head from 'next/head';

type TabValue = 'overview' | 'attractions' | 'accommodation' | 'restaurants' | 'weather' | 'plan';

interface TabNavigationProps {
  activeTab: TabValue;
  setActiveTab: (tab: TabValue) => void;
  destination: string;
}

const tabs: { value: TabValue; label: string }[] = [
  { value: 'overview', label: 'Overview' },
  { value: 'attractions', label: 'Attractions' },
  { value: 'accommodation', label: 'Stays' },
  { value: 'restaurants', label: 'Eat' },
  { value: 'weather', label: 'Weather' },
  { value: 'plan', label: 'Plan' },
];

const generateTableOfContentsSchema = (destination: string) => {
  const tabs = ['Overview', 'Attractions', 'Accommodation', 'Restaurants', 'Weather', 'Plan Your Trip'];
  return {
    "@context": "https://schema.org",
    "@type": "Table",
    "about": `${destination} Travel Guide`,
    "mainEntity": {
      "@type": "ItemList",
      "itemListElement": tabs.map((tab, index) => ({
        "@type": "ListItem",
        "position": index + 1,
        "name": tab,
        "url": `#${tab.toLowerCase().replace(/\s+/g, '-')}`
      }))
    }
  };
};

const TabNavigation: React.FC<TabNavigationProps> = ({ activeTab, setActiveTab, destination }) => {
  const tableOfContentsSchema = generateTableOfContentsSchema(destination);

  return (
    <div className="bg-gradient-to-r from-blue-500 to-purple-600 p-2 sm:p-4">
      <div className="max-w-4xl mx-auto">
        <Tabs defaultValue={activeTab} onValueChange={(value) => setActiveTab(value as TabValue)}>
          <TabsList className="h-auto items-center justify-center rounded-md bg-muted p-1 text-muted-foreground grid w-full grid-cols-3 sm:grid-cols-3 lg:grid-cols-6 gap-1 sm:gap-2">
            {tabs.map((tab) => (
              <TabsTrigger
                key={tab.value}
                value={tab.value}
                className="tab-trigger py-2 px-1 sm:px-3 text-xs sm:text-sm"
              >
                {tab.label}
              </TabsTrigger>
            ))}
          </TabsList>
        </Tabs>
      </div>
      <Head>
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(tableOfContentsSchema) }}
        />
      </Head>
    </div>
  );
};

export default TabNavigation;
