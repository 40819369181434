import Link from 'next/link';
import CloudinaryImage from './CloudinaryImage';

interface Destination {
  value: string;
  label: string;
  description: string;
}

interface RelatedDestinationsProps {
  destinations: Destination[];
}

export default function RelatedDestinations({ destinations }: RelatedDestinationsProps) {
  if (!destinations || destinations.length === 0) return null;

  return (
    <div className="w-full max-w-7xl mx-auto p-4 md:p-8 mt-12">
      <h2 id="relateddestinations" className="text-2xl md:text-3xl font-bold mb-6 text-center">Related Destinations</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
        {destinations.map((destination) => (
          <Link href={`/${destination.value.toLowerCase()}`} key={destination.value} className="block">
            <div className="bg-background rounded-lg shadow-md overflow-hidden transition-transform duration-300 hover:scale-105">
              <div className="relative h-36 lg:h-48">
                <CloudinaryImage  
                  src={`/images/${destination.value.toLowerCase()}`}
                  alt={destination.label}
                  width={300}
                  height={225}
                  sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 25vw"
                  className="object-cover w-full h-full"
                  quality={80}
                  format="auto"
                  onError={(e) => {
                    const target = e.target as HTMLImageElement;
                    target.src = '/images/placeholder-image.jpg';
                  }}
                />
              </div>
              <div className="p-3 lg:p-4">
                <h3 className="text-lg lg:text-xl font-semibold mb-1 lg:mb-2">{destination.label}</h3>
                <p className="text-muted-foreground text-xs lg:text-sm line-clamp-2 lg:line-clamp-3">
                  {destination.description}
                </p>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}