import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import CloudinaryImage from '../CloudinaryImage';

interface TopExperiencesCarouselProps {
  experiences: string[];
  destination: string;
}

const TopExperiencesCarousel: React.FC<TopExperiencesCarouselProps> = ({ experiences, destination }) => {
  return (
    <div className="mb-8">
      <h2 className="text-2xl font-bold mb-4">Top Experiences</h2>
      <Swiper
        modules={[Navigation, Pagination]}
        spaceBetween={20}
        slidesPerView={1}
        navigation
        pagination={{ clickable: true }}
        breakpoints={{
          640: {
            slidesPerView: 2,
          },
          768: {
            slidesPerView: 3,
          },
        }}
      >
        {experiences.map((experience, index) => (
          <SwiperSlide key={index}>
            <div className="bg-white dark:bg-gray-800 rounded-lg overflow-hidden shadow-md">
              <CloudinaryImage
                src={`/images/${destination.toLowerCase()}/${experience.toLowerCase().replace(/\s+/g, '-')}.jpg`}
                alt={experience}
                width={300}
                height={200}
                className="w-full h-48 object-cover"
              />
              <div className="p-4">
                <h3 className="text-lg font-semibold">{experience}</h3>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default TopExperiencesCarousel;