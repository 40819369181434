import { Metadata } from 'next';

// lib/seo.ts

export function generateDestinationSEOMetadata(destinationName: string) {
  return {
    title: `${destinationName} Travel Guide: Top Attractions, Best Times to Visit & More | Destinations AI`,
    description: `Experience the best of ${destinationName} with our comprehensive travel guide. Explore top attractions, local cuisine, transportation tips, and the best times to visit ${destinationName}.`
  };
}

export function generateCategorySEOMetadata(category: string, destination: string, placesCount: number) {
  return {
    title: `${placesCount} Best ${category} in ${destination} | Destinations AI`,
    description: `Discover the top ${category.toLowerCase()} in ${destination}. Explore detailed information, ratings, and reviews for the best places to visit.`
  };
}

export function generatePlaceSEOMetadata(placeName: string, destination: string, category: string) {
  return {
    title: `${placeName} - ${category} in ${destination} | Destinations AI`,
    description: `Explore ${placeName}, one of the best ${category.toLowerCase()} in ${destination}. Get detailed information, ratings, reviews, and plan your visit with Destinations AI.`
  };
}

export function generateDestinationsPageSEOMetadata() {
  return {
    title: "Explore Destinations | Destinations AI",
    description: "Discover a world of travel possibilities with our comprehensive list of destinations. Find your next adventure and plan your perfect trip with Destinations AI."
  };
}

export function generateToolsPageSEOMetadata(): Metadata {
  return {
    title: "Travel Tools | Destinations AI",
    description: "Enhance your travel experience with our suite of helpful tools. Plan, organize, and make the most of your trips with Destinations AI's innovative resources.",
    openGraph: {
      title: "Travel Tools | Destinations AI",
      description: "Enhance your travel experience with our suite of helpful tools. Plan, organize, and make the most of your trips with Destinations AI's innovative resources.",
      url: "https://smartertravel.com/tools"
    },
    alternates: {
      canonical: '/tools',
    }
  };
}

export function generateToolSEOMetadata(toolName: string, toolDescription: string) {
  return {
    title: `${toolName} | Travel Tools | Destinations AI`,
    description: toolDescription
  };
}