import React from 'react'
import Link from 'next/link'
import { Button } from '@/components/ui/button'
import { Sparkles } from 'lucide-react'

export function FloatingButton() {
  return (
    <div className="fixed p-1 md:p-2 bottom-8 right-8">
      <Link href="/next-gen-travel-search">
        <Button className="rounded-full shadow-lg bg-primary text-primary-foreground hover:bg-primary/90">
          <Sparkles className="mr-2 h-4 w-4" />
          AI Travel Planner
        </Button>
      </Link>
    </div>
  )
}