import { Destination } from '../../types';

export function generateTouristDestinationSchema(destinationData: Destination) {
  return {
    "@context": "https://schema.org",
    "@type": "TouristDestination",
    "name": destinationData.name,
    "description": destinationData.description,
    "touristType": {
      "@type": "Audience",
      "audienceType": [destinationData.category]
    },
    "includesAttraction": destinationData.top_attractions.map(attraction => ({
      "@type": "TouristAttraction",
      "name": attraction
    })),
    "geo": {
      "@type": "GeoCoordinates",
      "latitude": destinationData.latitude,
      "longitude": destinationData.longitude
    },
    "address": {
      "@type": "PostalAddress",
      "addressCountry": destinationData.country
    },
    "openingHoursSpecification": {
      "@type": "OpeningHoursSpecification",
      "hoursAvailable": destinationData.best_times_to_visit
    },
    "availableLanguage": {
      "@type": "Language",
      "name": destinationData.language
    },
    "currenciesAccepted": destinationData.currency,
    "publicAccess": true,
    "isAccessibleForFree": true,
    "additionalProperty": [
      {
        "@type": "PropertyValue",
        "name": "Safety Index",
        "value": destinationData.safety_index
      },
      {
        "@type": "PropertyValue",
        "name": "Walkability Score",
        "value": destinationData.walkability_score
      },
      {
        "@type": "PropertyValue",
        "name": "Population",
        "value": destinationData.population
      },
      {
        "@type": "PropertyValue",
        "name": "Average Hotel Price",
        "value": destinationData.avg_hotel_price
      },
      {
        "@type": "PropertyValue",
        "name": "Annual Visitors",
        "value": destinationData.annual_visitors
      }
    ],
    "amenityFeature": destinationData.transportation.map(transport => ({
      "@type": "LocationFeatureSpecification",
      "name": transport
    })),
    "potentialAction": {
      "@type": "TravelAction",
      "target": {
        "@type": "EntryPoint",
        "urlTemplate": `https://destinations.ai/${destinationData.name.toLowerCase().replace(/\s+/g, '-')}`
      }
    }
  };
}
