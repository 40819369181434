'use client';

import React, { useState, useEffect, Suspense, lazy } from 'react';
import { SessionProvider } from "next-auth/react";
import { Place, Category, Destination } from '../../types';
import { WeatherData } from '../../types/weather';
import { ErrorBoundary } from 'react-error-boundary';
import Head from 'next/head';
import { generateDestinationSEOMetadata } from '@/lib/seo';
import { generateOpenGraphMetadata } from '@/lib/openGraph';
import ClientErrorFallback from '@/components/ClientErrorFallback';
import { CostOfLivingData } from '../CostofLivingVisualization';
import HeroSection from './HeroSection';
import TabNavigation from './TabNavigation';
import OverviewTab from './OverviewTab';
import { FloatingButton } from '../FloatingButton';
import FAQSection from './FAQSection';
import { generateTouristDestinationSchema } from '@/lib/schema/touristDestination';
import TabValue from './TabNavigation';

const LazyAttractionsTab = lazy(() => import('./AttractionsTab'));
const LazyAccommodationTab = lazy(() => import('./AccommodationTab'));
const LazyRestaurantsTab = lazy(() => import('./RestaurantsTab'));
const LazyWeatherTab = lazy(() => import('./WeatherTab'));
const LazyPlanYourTripTab = lazy(() => import('./PlanYourTripTab'));

export type TabValue = 'overview' | 'attractions' | 'accommodation' | 'restaurants' | 'weather' | 'plan';

interface ClientDestinationPageProps {
  destinationData: Destination;
  initialPlaces: Place[];
  categories: Category[];
  userFavorites: any[]; // Replace 'any' with the correct type
  destination: string;
  relatedDestinations: any[]; // Replace 'any' with the correct type
  weatherData: WeatherData | null;
  costOfLivingData: CostOfLivingData | null | undefined;
}

const ClientDestinationPage = ({
  destinationData,
  initialPlaces,
  categories,
  userFavorites,
  destination,
  relatedDestinations,
  weatherData: initialWeatherData,
  costOfLivingData,
}: ClientDestinationPageProps) => {
  const [activeTab, setActiveTab] = useState<TabValue>('overview');
  const [weatherData, setWeatherData] = useState(initialWeatherData);

  useEffect(() => {
    const fetchWeatherData = async () => {
      try {
        const response = await fetch(`/api/weather?city=${encodeURIComponent(destination)}`, {
          signal: AbortSignal.timeout(30000) // 30 seconds timeout
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setWeatherData(data);
      } catch (error) {
        console.error('Error fetching weather data:', error);
        setWeatherData(null);
      }
    };

    if (!weatherData && destination) {
      fetchWeatherData();
    }
  }, [destination, weatherData]);

  const seoMetadata = generateDestinationSEOMetadata(destinationData.name);
  const openGraphMetadata = generateOpenGraphMetadata('destination', destinationData, 'https://destinations.ai');

  const generateFAQs = (destinationData: Destination) => {
    return [
      {
        question: `What are the top attractions in ${destinationData.name}?`,
        answer: `The top attractions in ${destinationData.name} include ${destinationData.top_attractions.slice(0, 3).join(', ')}, and more.`
      },
      {
        question: `When is the best time to visit ${destinationData.name}?`,
        answer: destinationData.best_times_to_visit
      },
      {
        question: `What is the local cuisine like in ${destinationData.name}?`,
        answer: `${destinationData.name} is known for its delicious local cuisine, which includes dishes like ${destinationData.local_cuisine.join(', ')}.`
      },
      {
        question: `What transportation options are available in ${destinationData.name}?`,
        answer: `In ${destinationData.name}, you can get around using ${destinationData.transportation.join(', ')}.`
      },
      {
        question: `What is the primary language spoken in ${destinationData.name}?`,
        answer: `The primary language spoken in ${destinationData.name} is ${Array.isArray(destinationData.language) ? destinationData.language.join(' and ') : destinationData.language}.`
      },
      {
        question: `What currency is used in ${destinationData.name}?`,
        answer: `The currency used in ${destinationData.name} is ${destinationData.currency} (${destinationData.currencySymbol}).`
      },
      {
        question: `Is ${destinationData.name} a safe city to visit?`,
        answer: `${destinationData.name} has a safety index of ${destinationData.safety_index}/100. As with any travel, it's always good to stay aware of your surroundings and take standard precautions.`
      },
      {
        question: `What is the average hotel price in ${destinationData.name}?`,
        answer: `The average hotel price in ${destinationData.name} is around ${destinationData.currencySymbol}${destinationData.avg_hotel_price.toFixed(2)} per night.`
      }
    ];
  };

  const faqs = generateFAQs(destinationData);

  const touristDestinationSchema = generateTouristDestinationSchema(destinationData);

  return (
    <SessionProvider>
      <ErrorBoundary FallbackComponent={ClientErrorFallback}>
        <Head>
          <title>{seoMetadata.title}</title>
          <meta name="description" content={seoMetadata.description} />
          {Object.entries(openGraphMetadata).map(([property, content]) => {
            if (typeof content === 'string') {
              return <meta key={property} property={`og:${property}`} content={content} />;
            } else if (Array.isArray(content) && property === 'images') {
              return content.map((image, index) => (
                <React.Fragment key={`og:image:${index}`}>
                  <meta property="og:image" content={image.url} />
                  <meta property="og:image:width" content={image.width.toString()} />
                  <meta property="og:image:height" content={image.height.toString()} />
                  <meta property="og:image:alt" content={image.alt} />
                </React.Fragment>
              ));
            }
            return null;
          })}
        </Head>
        <div className="flex flex-col min-h-screen bg-white dark:bg-gray-900">
          <HeroSection destinationData={destinationData} />
          <div className="sticky top-0 z-50">
            <TabNavigation activeTab={activeTab} setActiveTab={setActiveTab} destination={destinationData.name} />
          </div>
          <main className="flex-grow pt-4 max-w-4xl mx-auto px-4 w-full">
            {activeTab === 'overview' && (
              <OverviewTab 
                destinationData={destinationData} 
                initialPlaces={initialPlaces}
                categories={categories}
                relatedDestinations={relatedDestinations}
              />
            )}
            {activeTab === 'attractions' && (
              <Suspense fallback={<div>Loading attractions...</div>}>
                <LazyAttractionsTab 
                  destination={destination} 
                  initialPlaces={initialPlaces}
                  categories={categories}
                />
              </Suspense>
            )}
            {activeTab === 'accommodation' && (
              <Suspense fallback={<div>Loading accommodations...</div>}>
                <LazyAccommodationTab 
                  destinationData={destinationData}
                  initialPlaces={initialPlaces}
                />
              </Suspense>
            )}
            {activeTab === 'restaurants' && (
              <Suspense fallback={<div>Loading restaurants...</div>}>
                <LazyRestaurantsTab 
                  destinationData={destinationData}
                  initialPlaces={initialPlaces}
                />
              </Suspense>
            )}
            {activeTab === 'weather' && (
              <Suspense fallback={<div>Loading weather data...</div>}>
                <LazyWeatherTab 
                  weatherData={weatherData} 
                  destination={{
                    name: destinationData.name,
                    value: destinationData.id,
                    label: destinationData.name
                  }}
                  bestTimesToVisit={destinationData.best_times_to_visit} 
                />
              </Suspense>
            )}
            {activeTab === 'plan' && (
              <Suspense fallback={<div>Loading trip planner...</div>}>
                <LazyPlanYourTripTab 
                  destinationData={destinationData}
                  costOfLivingData={costOfLivingData || null}
                />
              </Suspense>
            )}
            <FAQSection faqs={faqs} />
          </main>
        </div>
        <FloatingButton />

        {/* FAQ Schema */}
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              "@context": "https://schema.org",
              "@type": "FAQPage",
              "mainEntity": faqs.map(faq => ({
                "@type": "Question",
                "name": faq.question,
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": faq.answer
                }
              }))
            })
          }}
        />

        <script 
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(touristDestinationSchema)
          }}
        />
      </ErrorBoundary>
    </SessionProvider>
  );
};

export default ClientDestinationPage;
