import React from 'react';
import { Destination, Category, Place } from '../../types';
import DestinationHighlights from './DestinationHighlights';
import TopExperiencesCarousel from './TopExperiencesCarousel';
import RelatedDestinations from '@/components/RelatedDestinations';
import Link from 'next/link';
import dynamic from 'next/dynamic';

const InteractiveMap = dynamic(() => import('./InteractiveMap'), { ssr: false });

interface OverviewTabProps {
  destinationData: Destination;
  initialPlaces: Place[];
  categories: Category[];
  relatedDestinations: Destination[];
}

const OverviewTab: React.FC<OverviewTabProps> = ({ destinationData, initialPlaces, categories, relatedDestinations }) => {
  return (
    <div className="container mx-auto px-4 py-8">
      <DestinationHighlights destinationData={destinationData} />
      <InteractiveMap 
        places={initialPlaces} 
        destination={{ name: destinationData.name, value: destinationData.value }}
        category="attractions"
      />
      <h2 id="placesvisit" className="text-2xl md:text-3xl font-bold mb-4 text-center">Types of Places in {destinationData.name}</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mb-12">
        {categories.map((category) => (
          <Link href={`/${destinationData.value}/${category.name.toLowerCase().replace(/\s+/g, '-')}`} key={category.name}>
            <div className="bg-background rounded-lg shadow-md overflow-hidden transition-transform duration-300 hover:scale-105">
              <div className="p-4">
                <h3 className="text-lg font-semibold mb-2">{category.name}</h3>
                <p className="text-muted-foreground text-sm">
                  Explore the best {category.name.toLowerCase()} in {destinationData.name}
                </p>
              </div>
            </div>
          </Link>
        ))}
      </div>
      {destinationData.top_experiences && destinationData.top_experiences.length > 0 && (
        <TopExperiencesCarousel 
          experiences={destinationData.top_experiences} 
          destination={destinationData.name} 
        />
      )}
      <RelatedDestinations destinations={relatedDestinations} />
    </div>
  );
}

export default OverviewTab;
