import React from 'react';
import { Destination } from '../../types';
import { Users, Globe, DollarSign, Clock, Sun, Compass } from 'lucide-react';

interface DestinationHighlightsProps {
  destinationData: Destination;
}

const DestinationHighlights: React.FC<DestinationHighlightsProps> = ({ destinationData }) => {
  const highlights = [
    { title: 'Population', value: destinationData.population.toLocaleString(), icon: <Users className="w-6 h-6" /> },
    { title: 'Language', value: destinationData.language, icon: <Globe className="w-6 h-6" /> },
    { title: 'Currency', value: destinationData.currency, icon: <DollarSign className="w-6 h-6" /> },
    { title: 'Time Zone', value: destinationData.timezone, icon: <Clock className="w-6 h-6" /> },
    { title: 'Best Time to Visit', value: destinationData.best_times_to_visit, icon: <Sun className="w-6 h-6" /> },
    { title: 'Known For', value: destinationData.known_for.join(', '), icon: <Compass className="w-6 h-6" /> },
  ];

  return (
    <div className="grid grid-cols-2 md:grid-cols-3 gap-4 mb-8 mt-4">
      {highlights.map((highlight, index) => (
        <div key={index} className="bg-gray-100 dark:bg-gray-800 p-4 rounded-lg flex items-start">
          <div className="mr-4 text-blue-500">{highlight.icon}</div>
          <div>
            <h3 className="text-lg font-semibold mb-2">{highlight.title}</h3>
            <p>{highlight.value}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default DestinationHighlights;
